// импортим весь цсс мтс-юи, потому что отдельно шрифты не экспортятся
import '@mts-ui/core/dist/mts-ui.core.css'
import { isBrowserSupported } from './is-browser-supported'

const template = `
      <div id="oldBrowserStub" class="old-browser">
        <div class="old-browser__header">
          <img src="img/header-logo.svg" class="header__logo" alt="mts logo" />
        </div>

        <div class="old-browser__main">
          <h1 class="old-browser__title">
            Ваш браузер устарел
          </h1>

          <p class="old-browser__text">
            В текущей версии браузера не гарантируется работа всех возможностей сервиса.
            Для быстрой и стабильной работы рекомендуем установить последнюю версию одного из этих браузеров:
          </p>

          <div class="old-browser__browsers-box">
            <div class="old-browser__browser">
              <a href="https://www.google.com/chrome" class="old-browser__browser-link">
                <img src="img/chrome.png" class="old-browser__browser-icon" alt="chrome">
                <span>Chrome</span>
              </a>
            </div>
            
             <div class="old-browser__browser">
              <a href="https://www.mozilla.org/ru/firefox/new/" class="old-browser__browser-link">
                <img src="img/firefox.png" class="old-browser__browser-icon" alt="firefox">
                <span>Firefox</span>
              </a>
            </div>
            
             <div class="old-browser__browser">
              <a href="https://www.opera.com/" class="old-browser__browser-link">
                <img src="img/opera.png" class="old-browser__browser-icon" alt="opera">
                <span>Opera</span>
              </a>
            </div>
          </div>
        </div>
      </div>
`

// todo: исправить верстку

const css = `
        body {
            margin:  0;
        }
        .old-browser {
            max-width: 1280px;
            margin: 0 auto;
        }
        .old-browser > .old-browser__header {
            padding: 10px 20px;
        }
        .old-browser > .old-browser__main {
            padding: 52px 20px 0;
        }
        
        .old-browser__main > .old-browser__title {
          font-family: MTS Sans;
          font-size: 30px;
          line-height: 34px;
          text-align: center;
          color: #001424;
          margin: 0 0 8px 0;
        }
        
        .old-browser__main > .old-browser__text {
          font-family: MTS Sans;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #626C77;
          margin: 0px auto 32px;
        }
      
        .old-browser__main > .old-browser__browsers-box {
          display: flex;
          max-width: 260px;
          margin: 0 auto 40px;
          justify-content: space-between;
        }
        
        .old-browser__browsers-box > .old-browser__browser {
          display: flex;
          flex-direction: column;
          width: 60px;
          height: 92px;
        }
        
        .old-browser__browser-link > .old-browser__browser-icon {
          max-width: 60px;
          max-height: 60px;
          margin-bottom: 8px;
        }
        
        .old-browser__browser > .old-browser__browser-link {
          cursor: pointer;
          font-family: MTS Sans;
          font-size: 17px;
          line-height: 24px;
          color: #007CFF;
          text-decoration: none;
          text-align: center;
        }
        
        @media (min-width: 600px) {
          .old-browser > .old-browser__header {
              padding: 20px 32px;
          }
          
          .old-browser > .old-browser__main {
              padding: 120px 40px 0;
          }
          
          .old-browser__main > .old-browser__title {
            font-size: 32px;
            line-height: 36px;
            margin: 0 0 4px 0;
          }
          
          .old-browser__main > .old-browser__text {
            font-size: 17px;
            line-height: 24px;
            margin: 0px auto 44px;
          }
        
          .old-browser__browsers-box > .old-browser__browser {
            width: 78px;
            height: 110px;
          }
        
          .old-browser__main > .old-browser__browsers-box {
            max-width: 340px;
          }
          
          .old-browser__browser-link > .old-browser__browser-icon {
            max-width: 78px;
            max-height: 78px;
          }
          
          .old-browser__browser > .old-browser__browser-link {
            font-size: 17px;
            line-height: 24px;
          }
        }
        
        @media (min-width: 1028px) {
          .old-browser > .old-browser__header {
              padding: 24px 52px;
          }
          
          .old-browser > .old-browser__main {
              padding: 200px 40px 0;
          }
          
          .old-browser__main > .old-browser__title {
            font-size: 40px;
            line-height: 52px;
          }
          
          .old-browser__main > .old-browser__text {
            font-size: 20px;
            line-height: 28px;
            margin: 0px auto 56px;
            max-width: 1054px;
          }
        
          .old-browser__main > .old-browser__browsers-box {
            max-width: 445px;
          }
          
          .old-browser__browsers-box > .old-browser__browser {
            width: 104px;
            height: 140px;
          }
          
          .old-browser__browser-link > .old-browser__browser-icon {
            max-width: 104px;
            max-height: 104px;
            margin-bottom: 12px;
          }
          
          .old-browser__browser > .old-browser__browser-link {
            font-size: 20px;
            line-height: 28px;
          }
        }
`
const head = document.getElementsByTagName('head')[0]
const style = document.createElement('style')
const $app = document.getElementById('app')

if (!isBrowserSupported(navigator.userAgent) && $app != null) {
  style.type = 'text/css'
  // @ts-expect-error This is required for IE8 and below.
  if (style.styleSheet) {
    // @ts-expect-error This is required for IE8 and below.
    style.styleSheet.cssText = css
  } else {
    style.appendChild(document.createTextNode(css))
  }

  head.appendChild(style)
  $app.innerHTML = template
}
